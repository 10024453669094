import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/app/ui/assets/svg/eye.svg'
import _imports_1 from '@/app/ui/assets/svg/invisible.svg'


const _hoisted_1 = { class: "inline-flex" }
const _hoisted_2 = { class: "w-full md:w-8/12" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row justify-between mt-4"
}
const _hoisted_4 = { class: "w-1/2 pr-3" }
const _hoisted_5 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_6 = { class: "inline-block relative w-full" }
const _hoisted_7 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "h-6"
}
const _hoisted_8 = {
  key: 1,
  src: _imports_1,
  alt: "",
  class: "h-6"
}
const _hoisted_9 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_10 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_11 = { class: "w-1/2 pr-3" }
const _hoisted_12 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_13 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_14 = { class: "flex flex-row justify-between" }
const _hoisted_15 = { class: "flex flex-row justify-between mt-4" }
const _hoisted_16 = { class: "flex flex-row justify-between mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_LpInput = _resolveComponent("LpInput")!
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_CheckboxV2 = _resolveComponent("CheckboxV2")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    onBack: _ctx.goBack,
    title: _ctx.titlePage,
    "full-page": "",
    "custom-class": "px-0",
    "vertical-separator": false,
    "leave-page-confirmation": _ctx.leavePageConfirmation,
    "onUpdate:leave-page-confirmation": _cache[14] || (_cache[14] = ($event: any) => (_ctx.leavePageConfirmation = $event)),
    onLeavePage: _ctx.onLeavePage,
    loading: _ctx.apiGetData.loading,
    error: !!this.apiGetData.errorType,
    errorType: this.apiGetData.errorType,
    onTryAgain: _ctx.fetchDetail
  }, {
    "top-right": _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_lp_button, {
          "text-color": "white",
          customClass: "w-full py-2.5",
          title: "Simpan",
          onClick: _ctx.onSaveForm,
          disabled: !_ctx.isFormValid
        }, null, 8, ["onClick", "disabled"])
      ])
    ]),
    data: _withCtx(() => [
      _createVNode("div", _hoisted_2, [
        (!_ctx.isFormTypeAdd)
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_DataWrapper, {
                  label: "ID",
                  asterisk: "",
                  class: "w-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_LpInput, {
                      modelValue: _ctx.form.id,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.id = $event)),
                      disabled: "",
                      disableBackground: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_DataWrapper, {
            label: "Nama Pengguna",
            asterisk: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.username,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.username = $event)),
                placeholder: "Masukkan nama pengguna",
                maxLength: "50"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "Kata Sandi",
            asterisk: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode("div", _hoisted_6, [
                _withDirectives(_createVNode("input", {
                  type: _ctx.passwordType,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.form.password = $event)),
                  placeholder: "Masukkan kata sandi",
                  maxlength: "50",
                  class: "block appearance-none w-full bg-white border px-4 py-3 pr-8 my-2 rounded text-12px  leading-tight focus:outline-none"
                }, null, 8, ["type"]), [
                  [_vModelDynamic, _ctx.form.password]
                ]),
                _createVNode("button", {
                  type: "button",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.changePasswordType && _ctx.changePasswordType(...args))),
                  class: "cursor-pointer absolute inset-y-0 right-0 flex items-center px-6 text-gray-500 focus:outline-none"
                }, [
                  (_ctx.passwordType === 'password')
                    ? (_openBlock(), _createBlock("img", _hoisted_7))
                    : (_openBlock(), _createBlock("img", _hoisted_8))
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_9, [
          _createVNode(_component_DataWrapper, {
            label: "Kode Eksternal Klien Branch",
            asterisk: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.clientCode,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.form.clientCode = $event)),
                placeholder: "Masukkan kode eksternal klien branch",
                maxLength: "20"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "Kode Klien Branch",
            asterisk: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.clientCodeGenesis,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.clientCodeGenesis = $event)),
                placeholder: "Masukkan kode klien branch",
                maxLength: "20"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_10, [
          _createVNode("div", _hoisted_11, [
            _createVNode(_component_DataWrapper, {
              label: "Kode Klien Parent",
              asterisk: "",
              class: "w-full"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_LpInput, {
                  modelValue: _ctx.form.clientId,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.form.clientId = $event)),
                  placeholder: "Masukkan kode klien parent",
                  maxLength: "20"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode("div", _hoisted_12, [
          _createVNode(_component_DataWrapper, {
            label: "Nama Klien Branch",
            asterisk: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.company,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.company = $event)),
                placeholder: "Masukkan nama klien",
                maxLength: "500"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "Kota Klien",
            asterisk: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.city,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.form.city = $event)),
                placeholder: "Masukkan kota klien",
                maxLength: "50"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_13, [
          _createVNode(_component_DataWrapper, {
            label: "Apakah ini Klien Crossdock ?",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CheckboxV2, {
                label: "YA",
                class: "mt-4 mb-10 ml-1",
                textSize: 14,
                value: _ctx.form.isClientCrossdocking,
                "onUpdate:value": _ctx.updateCrossDock,
                labelWeight: "normal"
              }, null, 8, ["value", "onUpdate:value"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_14, [
          _createVNode(_component_DataWrapper, {
            label: "Kode Kota Klien",
            optional: "",
            class: "w-full mr-4"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.client3lc,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.form.client3lc = $event)),
                placeholder: "Masukkan kode kota klien",
                maxLength: "20"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_DataWrapper, {
            label: "ID Klien Branch",
            optional: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.clientIdGenesis,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.form.clientIdGenesis = $event)),
                format: _ctx.formatNumber,
                placeholder: "Masukkan id klien branch",
                maxLength: "20"
              }, null, 8, ["modelValue", "format"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_15, [
          _createVNode(_component_DataWrapper, {
            label: "Webhook",
            optional: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.webhook,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.form.webhook = $event)),
                placeholder: "Masukkan webhook",
                maxLength: "500"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _createVNode("div", _hoisted_16, [
          _createVNode(_component_DataWrapper, {
            label: "Webhook Token",
            optional: "",
            class: "w-full"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LpInput, {
                modelValue: _ctx.form.webhookToken,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.form.webhookToken = $event)),
                placeholder: "Masukkan webhook token",
                maxLength: "1000"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["onBack", "title", "leave-page-confirmation", "onLeavePage", "loading", "error", "errorType", "onTryAgain"]))
}